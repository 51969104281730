import React, { useContext } from 'react';
import { ScrollView, StyleSheet, View, Image, TouchableOpacity, Text, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '../AuthContext'; // Importiamo l'AuthContext

const HomeScreen = ({ navigation }) => {
  const { signOut } = useContext(AuthContext); // Usiamo l'AuthContext

  const handleLogout = async () => {
    try {
      await AsyncStorage.removeItem('jwtToken');
      signOut(); // Usiamo la funzione di signOut dall'AuthContext
    } catch (error) {
      console.error('Error logging out:', error);
      Alert.alert('Logout failed', 'An error occurred while logging out');
    }
  };

  return (
    <View style={styles.container}>
      
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scrollcontainer}>
        <Image source={require('../assets/logo.jpg')} style={styles.logo} />
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Selection')}>
          <Text style={styles.text}>INVENTORY</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('New Order')}>
          <Text style={styles.text}>REGISTER NEW ORDER</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('New Customer')}>
          <Text style={styles.text}>REGISTER CUSTOMER</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('New Reseller')}>
          <Text style={styles.text}>REGISTER RESELLER</Text>
        </TouchableOpacity>
      </ScrollView>
      <TouchableOpacity style={styles.logoutButton} onPress={handleLogout}>
          <Text style={styles.logoutText}>Logout</Text>
        </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  scrollcontainer:{
    justifyContent: 'center',
flex:1

  },

  
  logo: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
    marginTop: 50,
    marginBottom: 20,
  },
  text:{
    color:"black",
    fontSize:18
  },
  button:{
    borderWidth:1,
    borderColor:"black",
    padding:20,
    alignItems:"center",
    marginBottom:20,
    backgroundColor:"#c6c6c62b"
  },
  logoutButton: {
    position: 'absolute',
    top: 60, // Adjusted position
    right: 40,
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 5,
  },
  logoutText: {
    color: 'white',
    fontSize: 16,
  }
});

export default HomeScreen;
