import React from 'react';
import { ScrollView, StyleSheet, View, Image, TouchableOpacity, Text, Alert } from 'react-native';


const RingBuilder = ({ navigation }) => {



  return (
    <View style={styles.container}>
      {/* Title */}
      <Text style={styles.title}>Select Your Model</Text>

      {/* Horizontal ScrollView for buttons */}
      <ScrollView horizontal style={styles.scroll} contentContainerStyle={styles.scrollContent}>
        
        {/* Solitaire Button */}
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Solitaire')}>
          <Image source={require('../assets/solitaire.jpeg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Solitaire</Text>
        </TouchableOpacity>

        {/* Diamond Band Button */}
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Diamond Band')}>
          <Image source={require('../assets/diamondband.jpeg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Diamond Band</Text>
        </TouchableOpacity>

        {/* Halo Button */}
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Halo')}>
          <Image source={require('../assets/halo.jpeg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Halo</Text>
        </TouchableOpacity>

        {/* Three Stones Button */}
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Three Stones')}>
          <Image source={require('../assets/trilogy.jpeg')} style={styles.buttonImage} />
          <Text style={styles.buttonText}>Three Stones</Text>
        </TouchableOpacity>

      </ScrollView>

    
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F5F5F5',
  },
  title: {
    fontSize: 24,
    marginVertical: 20,
    color: 'black',
    fontFamily: 'Gilda', // Make sure you use the custom font here as well
marginBottom:40
  },
  scroll: {
    flexGrow: 0,
  },
  scrollContent: {
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  button: {
    alignItems: 'center',
    marginHorizontal: 10,
    padding: 20,
    backgroundColor: '#fff',

    borderRadius: 5,
  },
  buttonImage: {
    width: 300,
    height: 300,
    resizeMode: 'contain',
    marginBottom: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'Gilda', // Make sure you use the custom font here as well

  },

});

export default RingBuilder;
