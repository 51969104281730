import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, FlatList, ActivityIndicator, TouchableOpacity, SafeAreaView, Image, ScrollView } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const ResellerInventory = ({ route }) => {
  const navigation = useNavigation();

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [useCache, setUseCache] = useState(false);
  const [isCacheChosen, setIsCacheChosen] = useState(false);
  const customer = route.params?.customer;

  const handleMakeInvoice = () => {
    navigation.navigate('Reseller Consignment', {
      selectedItems: selectedItems,
      customerData: customer,
    });
  };

  useEffect(() => {
    if (isCacheChosen) {
      fetchAllProducts(1, [], useCache);
    }
  }, [isCacheChosen]);

  const fetchAllProducts = async (page, allProducts, useCache, retryCount = 0) => {

    setIsLoading(true);
    const url = useCache
    ? `${proxyUrl}${encodeURIComponent(`${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=${page}`)}`
    : `${proxyUrl}${encodeURIComponent(`${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=${page}&_=${new Date().getTime()}`)}`;


    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const data = await response.json();
      const newAllProducts = allProducts.concat(data);

      if (data.length === 100) { // Check if there might be more products
        fetchAllProducts(page + 1, newAllProducts);
      } else {
        setProducts(newAllProducts);
        setFilteredProducts(newAllProducts);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (retryCount < 3) {
        setTimeout(() => fetchAllProducts(page, allProducts, retryCount + 1), 2000);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.sku.toLowerCase().includes(query.toLowerCase()) ||
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const addItemToCart = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const removeItemFromCart = (id) => {
    const updatedItems = selectedItems.filter(item => item.id !== id);
    setSelectedItems(updatedItems);
  };

  // New function to fetch reseller products based on the 'location' attribute
  const fetchResellerProducts = () => {
    // Assuming customer.id is the reseller's ID that matches the product's location
    const resellerProducts = products.filter(product => {
      const locationMeta = product.meta_data.find(meta => meta.key === 'location');
      
      // Ensure that locationMeta is available and check if it matches the customer ID
      if (locationMeta && locationMeta.value == customer.id) {
        return true; // This product belongs to the current customer/reseller
      }
  
      return false; // This product does not belong to the customer
    });
  
    if (resellerProducts.length === 0) {
    } else {
      setSelectedItems([...selectedItems, ...resellerProducts]);
    }
  };
  
  

  const renderItem = ({ item }) => {
    const numberusdAttr = item.attributes.find(attr => attr.name.toLowerCase() === 'numberusd');
    const numberusd = numberusdAttr ? numberusdAttr.options[0] : ' ';
  
    // Count the number of times this item is already in the selectedItems list
    const itemCount = selectedItems.filter(selectedItem => selectedItem.id === item.id).length;
    const isDuplicate = itemCount > 1;
  
    return (
<View style={[styles.row, isDuplicate && styles.duplicateRow]}>
  <Image
    source={{ uri: item.images[0]?.src || 'https://via.placeholder.com/60' }} // Placeholder image
    style={styles.productImage}
  />
  <Text style={styles.cellSKU}>{item.sku}</Text>
  <Text style={styles.cellName}>{item.name}</Text>
  <Text style={styles.cellUSD}>{numberusd}</Text>
  <Text style={styles.cellPrice}>{item.regular_price}</Text>
  <TouchableOpacity onPress={() => addItemToCart(item)} style={styles.addButton}>
    <FontAwesome name="plus" size={24} color="white" />
  </TouchableOpacity>
</View>
    );
  };

  return (
 <SafeAreaView style={styles.safeArea}>
      {!isCacheChosen ? (
        <View style={styles.cacheOptionContainer}>
          <TouchableOpacity style={styles.cacheButton} onPress={() => { setUseCache(true); setIsCacheChosen(true); }}>
            <Text style={styles.cacheButtonText}>Fetch from Cache</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.cacheButton} onPress={() => { setUseCache(false); setIsCacheChosen(true); }}>
            <Text style={styles.cacheButtonText}>Fetch Without Cache</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.container}>
          <TextInput
            style={styles.input}
            placeholder="Search by SKU or name"
            placeholderTextColor="#888"
            value={searchQuery}
            onChangeText={handleSearch}
          />
          {isLoading ? (
            <ActivityIndicator size="large" color="#000" />
          ) : (
            <View style={styles.content}>
              <View style={styles.headerRow}>
              <Text style={styles.headerCellImage}>Image</Text>
  <Text style={styles.headerCellSKU}>SKU</Text>
  <Text style={styles.headerCellName}>Name</Text>
  <Text style={styles.headerCellUSD}>USD</Text>
  <Text style={styles.headerCellPrice}>Price</Text>
  <Text style={styles.headerCellAdd}>Add</Text>
              </View>
              <FlatList
                data={filteredProducts}
                renderItem={renderItem}
                keyExtractor={(item) => item.id.toString()}
                contentContainerStyle={styles.listContainer}
                showsVerticalScrollIndicator={false}
              />
              <View style={styles.selectedItemsContainer}>
                <Text style={styles.selectedItemsTitle}>Selected Items</Text>
                <ScrollView style={styles.selectedItemsScroll}>
                  {selectedItems.map((item, index) => (
                    <View key={item.id.toString()} style={styles.selectedItemContainer}>
                      <View style={styles.selectedItemTextContainer}>
                        <Text style={styles.selectedItemText}>{index + 1}. {item.name}</Text>
                        <Text style={styles.selectedItemText}>{item.sku}</Text>
                      </View>
                      <TouchableOpacity onPress={() => removeItemFromCart(item.id)} style={styles.removeButton}>
                        <FontAwesome name="minus" size={24} color="white" />
                      </TouchableOpacity>
                    </View>
                  ))}
                </ScrollView>
                <TouchableOpacity style={styles.invoiceButton} onPress={handleMakeInvoice}>
                  <Text style={styles.invoiceButtonText}>Make Invoice</Text>
                </TouchableOpacity>

                {/* New Button to Fetch Reseller Products */}
                <TouchableOpacity style={styles.invoiceButton} onPress={fetchResellerProducts}>
                  <Text style={styles.invoiceButtonText}>Fetch Reseller Products</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    color: '#000',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  listContainer: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    paddingVertical: 10,
    paddingHorizontal: 5,
    marginVertical: 5,
    borderRadius: 5,
    backgroundColor: '#f9f9f9',
  },
  duplicateRow: {
    backgroundColor: '#d0e7ff', // Light blue background for duplicates
  },
  cell: {
    width: 100, // Match this width with the headerCell to align them perfectly
    fontSize: 16,
    paddingHorizontal: 10,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  productImage: {
    width: 60,
    height: 60,
    borderRadius: 5,
  },
  addButton: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
    alignSelf: 'center', // Ensures that the button is centered in its cell

  },
  selectedItemsContainer: {
    marginTop: 20,
    maxHeight: '50%',
  },
  selectedItemsTitle: {
    color: '#000',
    fontSize: 18,
    marginBottom: 10,
  },
  selectedItemsScroll: {
    flexGrow: 1,
  },
  selectedItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginVertical: 5,
    borderRadius: 5,
    backgroundColor: '#f9f9f9',
  },
  selectedItemText: {
    color: '#000',
    fontSize: 16,
  },
  invoiceButton: {
    backgroundColor: 'blue',
    padding: 15,
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 20,
  },
  invoiceButtonText: {
    color: 'white',
    fontSize: 18,
  },
  removeButton: {
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 5,
  },
  cacheOptionContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cacheButton: {
    backgroundColor: 'gray',
    padding: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  cacheButtonText: {
    color: 'white',
    fontSize: 18,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    paddingVertical: 10,
    paddingHorizontal: 5,
    backgroundColor: '#ddd', // Lighter background for header
  },
  headerCell: {
    width: 100, // Define a fixed width for each cell to ensure alignment with data cells
    fontSize: 16,
    paddingHorizontal: 10,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,

  },
  textCell: {
    numberOfLines: 1,
    ellipsizeMode: 'tail', // Ellipsis if text is too long to fit
  },
  headerCellImage: {
    width: 60, // Smaller width for Image column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  headerCellSKU: {
    width: 80, // Small width for SKU column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  headerCellName: {
    width: 200, // Larger width for Name column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  headerCellUSD: {
    width: 80, // Smaller width for USD column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  headerCellPrice: {
    width: 80, // Smaller width for Price column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  headerCellAdd: {
    width: 60, // Small width for Add column
    fontSize: 16,
    paddingHorizontal: 5,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellImage: {
    width: 60,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellSKU: {
    width: 80,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellName: {
    width: 200,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellUSD: {
    width: 80,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellPrice: {
    width: 80,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
  cellAdd: {
    width: 60,
    fontSize: 16,
    paddingHorizontal: 5,
    color: '#000',
    textAlign: 'center',
    paddingVertical: 10,
  },
});

export default ResellerInventory;
