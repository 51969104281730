import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, SafeAreaView, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { printAsync } from 'expo-print';
import Base64ImageComponent from '../assets/Base64ImageComponent';
import DateTimePicker from '@react-native-community/datetimepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MakeInvoice = ({ navigation, route }) => {
  const { selectedItems = [], customerData } = route.params;
  const [goldCarat, setGoldCarat] = useState("750");
  const [initialTotalCarats, setInitialTotalCarats] = useState({});

  const [stoneWeights, setStoneWeights] = useState({});
  const [goldWeights, setGoldWeights] = useState({});
  const [totalCarats, setTotalCarats] = useState({});
  const [invoiceNo, setInvoiceNo] = useState(""); // State for Invoice No.
  const [dueDate, setDueDate] = useState(new Date()); // State for Due Date
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Cash"); // State for selected payment method
  const [invoiceDate, setInvoiceDate] = useState(new Date()); // State for Invoice Date
  const [vatPercentage, setVatPercentage] = useState("21"); // State for VAT Percentage
  const [itemsWithNewPrices, setItemsWithNewPrices] = useState([]); // State for items with new prices
  const [customProducts, setCustomProducts] = useState([]);

  const countryNames = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo (Democratic Republic)",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (North)",
    KR: "Korea (South)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MK: "North Macedonia",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States of America",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };

  useEffect(() => {
    const calculateTotalCaratWeights = () => {
      const caratWeights = {};
      selectedItems.forEach(item => {
        const weightCentralStone = parseFloat(item.attributes.find(attr => attr.slug === 'pa_weight-central-stone')?.options[0] || '0');
        const weightPaveStones = parseFloat(item.attributes.find(attr => attr.slug === 'pa_pave-diamonds-weight')?.options[0] || '0');
        const weightSideStones = parseFloat(item.attributes.find(attr => attr.slug === 'pa_weight-side-stones')?.options[0] || '0');
        const looseDiamondWeight = parseFloat(item.attributes.find(attr => attr.slug === 'pa_diamond-carat')?.options[0] || '0');
  
        const totalCarat = weightCentralStone + weightPaveStones + weightSideStones + looseDiamondWeight;
        caratWeights[item.id] = totalCarat.toFixed(2);
      });
      setInitialTotalCarats(caratWeights); // Set initial total carats
      setTotalCarats(caratWeights);
    };
  
    calculateTotalCaratWeights();
    updatePrices();
  }, [selectedItems, vatPercentage, customProducts]);
  



  const addCustomProduct = () => {
    setCustomProducts(prevState => [
        ...prevState,
        {
            sku: '',
            quantity: 1,
            stoneWeight: '',
            goldWeight: '',
            goldCarat: '750',
            price: '', // Empty by default
            priceExcludingVAT: '', // Add price excluding VAT
            priceWithVAT: '' // Add price including VAT
        }
    ]);
};

  const removeCustomProduct = (index) => {
    setCustomProducts(prevState => prevState.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const updatedCarats = { ...totalCarats };
    for (const id in stoneWeights) {
      updatedCarats[id] = stoneWeights[id];
    }
    setTotalCarats(updatedCarats);
  }, [stoneWeights]);

  const updatePrices = () => {
    const vatRate = parseFloat(vatPercentage) / 100;
  
    const itemsWithUpdatedPrices = selectedItems.map(item => {
      let priceWithVAT = parseFloat(item.price);
      if (isNaN(priceWithVAT)) {
        priceWithVAT = 0.00;
      }
  
      // Calculate the base price by removing the VAT
      const priceExcludingVAT = priceWithVAT / (1 + vatRate);
      const newPrice = priceWithVAT;
  
      return {
        ...item,
        priceExcludingVAT: parseFloat(priceExcludingVAT.toFixed(2)),
        newPrice: parseFloat(newPrice.toFixed(2)) // Keeping it as a number
      };
    });
  
    setItemsWithNewPrices(itemsWithUpdatedPrices);
  };
  
  

  const handleStoneWeightChange = (productId, weight) => {
    setStoneWeights(prevState => ({
      ...prevState,
      [productId]: weight
    }));
  };

  const handleGoldWeightChange = (productId, weight) => {
    setGoldWeights(prevState => ({
      ...prevState,
      [productId]: weight
    }));
  };

  const handleVatChange = (text) => {
    if (text === "" || !isNaN(parseFloat(text))) {
      setVatPercentage(text);
    }
  };


  

  const printInvoiceData = async () => {
    if (selectedItems.length === 0 && customProducts.length === 0) {
      throw new Error("No items selected for invoice.");
  }

  if (selectedItems.some(item => !item.price || isNaN(parseFloat(item.price))) ||
      customProducts.some(product => !product.price || isNaN(parseFloat(product.price)))) {
      throw new Error("Some products have invalid price values. Please review and correct.");
  }
  
    try {
  
      // Combine selectedItems and customProducts into a single list
      const allItems = [
        ...itemsWithNewPrices.map(item => ({
            ...item,
            quantity: 1,
            stoneWeight: stoneWeights[item.id] !== undefined ? stoneWeights[item.id] : initialTotalCarats[item.id],
            goldWeight: goldWeights[item.id] || '',
            goldCarat,
            priceWithVAT: item.newPrice || 0, // Use VAT-inclusive price
            priceExcludingVAT: item.priceExcludingVAT || 0
        })),
        ...customProducts.map((product, index) => {
            const vatRate = parseFloat(vatPercentage) / 100;
            const priceWithVAT = parseFloat(product.price);
            const priceExcludingVAT = priceWithVAT / (1 + vatRate);
            return {
                ...product,
                quantity: product.quantity || 1,
                priceWithVAT: priceWithVAT || 0,
                priceExcludingVAT: parseFloat(priceExcludingVAT.toFixed(2)) || 0,
            };
        }),
    ];
  

    const totalExcludingVAT = allItems.reduce((acc, item) => {
      const price = parseFloat(item.priceExcludingVAT) || 0;
      return acc + (price * (item.quantity || 1));
  }, 0);

  const totalIncludingVAT = allItems.reduce((acc, item) => {
      const price = parseFloat(item.priceWithVAT) || 0;
      return acc + (price * (item.quantity || 1));
  }, 0);

  const newVATAmount = totalIncludingVAT - totalExcludingVAT;

  const formatCurrency = (value) => {
      if (isNaN(value) || value === undefined || value === null) {
          return '0,00'; // Default to 0,00 if the value is invalid
      }
      return parseFloat(value).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };


  
      // Invoice HTML content generation
      const invoiceContent = `
        <html>
          <body>
            <div style="text-align: center;">
              <img src="${Base64ImageComponent()}" alt="Logo" style="width: 250px; height: auto;" />
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
              <div style="border: 1px solid black; padding: 10px; width: 45%;">
                <p style="margin:0"><strong>${customerData.first_name} ${customerData.last_name}</strong></p>
                <p style="margin:0">${customerData.billing.address_1}</p>
                <p style="margin:0">${customerData.billing.postcode}</p>
<p style="margin:0">${countryNames[customerData.billing.country] || customerData.billing.country}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; margin-bottom: 20px; width: 100%;">
              <div style="border: 1px solid black; padding: 10px; width: 45%; display: flex; align-items: center;">
                <p style="margin: 0;"><strong>Invoice No.:</strong> ${invoiceNo}</p>
              </div>
              <div>
                <p style="margin: 0;"><strong>Antwerp</strong>, ${invoiceDate.toLocaleDateString()}</p>
              </div>
            </div>
            <table style="border-collapse: collapse;width: 100%;">
              <thead>
                <tr>
                  <th style="border: 1px solid black;padding:10px">N° Jewel</th>
                  <th style="border: 1px solid black;padding:10px">Quantity</th>
                  <th style="border: 1px solid black;padding:10px">Weight Stones/ct</th>
                  <th style="border: 1px solid black;padding:10px">Weight Gold</th>
                  <th style="border: 1px solid black;padding:10px">Gold/K.</th>
                  <th style="border: 1px solid black;padding:10px">Price/Unit</th>
                  <th style="border: 1px solid black;padding:10px">Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${allItems.map(item => `
                  <tr>
                    <td style="border: 1px solid black;padding:10px">${item.sku}</td>
                    <td style="border: 1px solid black;padding:10px">${item.quantity}</td>
                    <td style="border: 1px solid black;padding:10px">${item.stoneWeight || ''}</td>
                    <td style="border: 1px solid black;padding:10px">${item.goldWeight || ''}</td>
                    <td style="border: 1px solid black;padding:10px">${item.goldCarat || goldCarat}</td>
                  <td style="border: 1px solid black;padding:10px">${formatCurrency(item.priceWithVAT)} €</td>
                  <td style="border: 1px solid black;padding:10px">${formatCurrency(item.priceWithVAT * (item.quantity || 1))} €</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; margin-top: 40px;margin-bottom:20px">
              <div>
                <p style="margin:0"><strong>Due Date:</strong> ${dueDate.toLocaleDateString()}</p>
                <p style="margin:0"><strong>Payment Method:</strong> ${selectedPaymentMethod}</p>
              </div>
              <div>
                <table style="border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT excl.</strong></td>
                    <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalExcludingVAT)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>VAT ${vatPercentage}%</strong></td>
                    <td style="border: 1px solid black; padding: 10px;">${formatCurrency(newVATAmount)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT incl.</strong></td>
                    <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalIncludingVAT)} €</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="margin-bottom:20px">
              <div style="border: 1px solid black; padding: 10px;width:50%">
                <p style="margin:0;font-size:14px">Please pay in € only to: KBC Bank </p>
                <p style="margin:0;font-size:14px">Name: The shamrock-Orogem jewelers </p>
                <p style="margin:0;font-size:14px">IBAN: BE52737001907509 </p>
                <p style="margin:0;font-size:14px">SWIFT: KREDBEBB</p>
              </div>
            </div>
<div style="position: absolute; bottom: 80px; left: 0; width: 100%; text-align: left; font-size: 10px; max-width: 70%;">
  <p style="margin: 0;">Article 8: Jurisdiction and applicable Law</p>
  <p style="margin: 0;">All disputes arising from this here contract are subject to Belgian Law. The Courts of Antwerp are the forum to resolve any such dispute, notwithstanding the seller's right to designate any other jurisdiction if he so chooses.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">*Krachtens de artikelen 2 en 39bis,1° W.BTW wordt geen Belgische BTW in rekening gebracht.</p>
  <p style="margin: 0;">Above mentioned goods will be considered on consignment until full receipt of the payment of this invoice.</p>
  <p style="margin: 0;">The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions. The seller hereby guarantees that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">**The diamonds herein invoiced are exclusively of natural origin and untreated based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
</div>
<footer style="position: absolute; bottom: 20px; left: 0; width: 100%; text-align: center; font-size: 12px;">
  <p style="margin: 0;">The Shamrock . Vestingstraat 38 - 2018 Antwerpen - T +32 (0)3 213 27 89 - yannick@juwelenorogem.be</p>
  <p style="margin: 0;">Bank KBC 737-0019075-09 - BTW BE 436 048 553 - H.R. Antwerpen 33.99.44</p>
</footer>
          </body>
        </html>
      `;
  
      // Printing the invoice
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(invoiceContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: invoiceContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
    }
  };
  

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.row}>
            <Text style={[styles.columnTitle, { width: '10%' }]}>N° Jewel</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Quantity</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Weight Stones/ct</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Weight Gold</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Gold/K.</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Price/Unit</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Total Price</Text>
          </View>

          {itemsWithNewPrices.map((item, index) => (
  <View key={index} style={styles.row}>
    <Text style={styles.item}>{item.sku}</Text>
    <Text style={styles.item}>1</Text>
    <TextInput
      style={styles.input}
      placeholder="Enter Weight (ct)"
      placeholderTextColor="#888888"
      keyboardType="numeric"
      value={stoneWeights[item.id] !== undefined ? stoneWeights[item.id] : initialTotalCarats[item.id]}
      onChangeText={text => handleStoneWeightChange(item.id, text)}
    />
    <TextInput
      style={styles.input}
      placeholder="Enter Weight (g)"
      placeholderTextColor="#888888"
      keyboardType="numeric"
      value={goldWeights[item.id] || ''}
      onChangeText={text => handleGoldWeightChange(item.id, text)}
    />
    <TextInput
      style={styles.input}
      defaultValue="750"
      keyboardType="numeric"
      onChangeText={text => setGoldCarat(text)}
    />
    <Text style={styles.item}>{item.newPrice} €</Text>
    <Text style={styles.item}>{item.newPrice} €</Text>
  </View>
))}

{customProducts.map((product, index) => (
  <View key={index} style={styles.customProductContainer}>
    <TextInput
      style={styles.input}
      placeholder="Enter SKU"
      value={product.sku}
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].sku = text;
        setCustomProducts(updatedProducts);
      }}
    />
    <TextInput
      style={styles.input}
      placeholder="Quantity"
      value={String(product.quantity)}
      keyboardType="numeric"
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].quantity = parseInt(text, 10) || 1;
        setCustomProducts(updatedProducts);
      }}
    />
    <TextInput
      style={styles.input}
      placeholder="Weight Stones/ct"
      value={product.stoneWeight}
      keyboardType="numeric"
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].stoneWeight = text;
        setCustomProducts(updatedProducts);
      }}
    />
    <TextInput
      style={styles.input}
      placeholder="Weight Gold"
      value={product.goldWeight}
      keyboardType="numeric"
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].goldWeight = text;
        setCustomProducts(updatedProducts);
      }}
    />
    <TextInput
      style={styles.input}
      placeholder="Gold Carat"
      value={product.goldCarat}
      keyboardType="numeric"
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].goldCarat = text;
        setCustomProducts(updatedProducts);
      }}
    />
    <TextInput
      style={styles.input}
      placeholder="Price/Unit"
      value={product.price}
      keyboardType="numeric"
      onChangeText={text => {
        const updatedProducts = [...customProducts];
        updatedProducts[index].price = text;
        setCustomProducts(updatedProducts);
      }}
    />
    <TouchableOpacity onPress={() => removeCustomProduct(index)}>
      <Text style={styles.removeButtonText}>Remove</Text>
    </TouchableOpacity>
  </View>
))}


<TouchableOpacity style={styles.button} onPress={addCustomProduct}>
  <Text style={styles.buttonText}>Add Custom Product</Text>
</TouchableOpacity>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Customer:</Text>
            <Text style={styles.customerInfo}>{customerData.first_name} {customerData.last_name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>VAT Percentage (%):</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter VAT Percentage"
              keyboardType="numeric"
              value={vatPercentage}
              onChangeText={handleVatChange}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Invoice No.:</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter Invoice No."
              value={invoiceNo}
              onChangeText={text => setInvoiceNo(text)}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Due Date:</Text>
            {Platform.OS === 'web' ? (
              <DatePicker
                selected={dueDate}
                onChange={date => setDueDate(date)}
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker" // Custom class to style it
                calendarClassName="custom-calendar" // Custom class for the calendar
                popperPlacement="bottom-end" // Positioning the calendar
                modal="true"
              />
            ) : (
              <DateTimePicker
                value={dueDate}
                mode="date"
                display="default"
                onChange={(event, date) => setDueDate(date)}
                style={styles.input3}
              />
            )}
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Invoice Date:</Text>
            {Platform.OS === 'web' ? (
              <DatePicker
                selected={invoiceDate}
                onChange={date => setInvoiceDate(date)}
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker" // Custom class to style it
                calendarClassName="custom-calendar" // Custom class for the calendar
              />
            ) : (
              <DateTimePicker
                value={invoiceDate}
                mode="date"
                display="default"
                onChange={(event, date) => setInvoiceDate(date)}
                style={styles.input3}
              />
            )}
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Payment Method:</Text>
            <Picker
              style={styles.input2}
              selectedValue={selectedPaymentMethod}
              onValueChange={(itemValue, itemIndex) => setSelectedPaymentMethod(itemValue)}
              itemStyle={{ color: 'black' }}
              mode="dropdown" // Set the mode to dropdown for web
            >
              <Picker.Item label="Cash" value="Cash" color="black" />
              <Picker.Item label="Credit Card" value="Credit Card" color="black" />
              <Picker.Item label="Bank Transfer" value="Bank Transfer" color="black" />
            </Picker>
          </View>
          <TouchableOpacity style={styles.button} onPress={printInvoiceData}>
            <Text style={styles.buttonText}>Print Invoice</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20,
  },
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    paddingVertical: 15, // Increased for better visual separation
    width: '100%',
  },
  columnTitle: {
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
    paddingHorizontal: 5, // Add horizontal padding for visual balance
  },
  safeArea: {
    flex: 1,
  },
  item: {
    color: 'white',
    fontSize: 16,
    flex: 1,
    textAlign: 'center',
    paddingHorizontal: 5,
  },
  customerInfo: {
    color: 'white',
    fontSize: 16,
    flex: 1,
    padding: 10, // Added padding for separation
    textAlign: 'left', // Align customer information to the left
  },
  input: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    padding: 10, // Increased padding for more comfortable text entry
    flex: 1,
    textAlign: 'center',
    marginHorizontal: 5, // Added margin for better spacing between inputs
    borderRadius: 5, // Added rounded corners for better aesthetics
  },
  input2: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'black',
    padding: 10,
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 5,
    textAlign: 'center',
  },
  button: {
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontSize: 18,
  },
  removeButtonText: {
    color: 'red',
    fontSize: 14,
    marginLeft: 10,
  },
  customProductContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginVertical: 10,
    width: '100%',
  },
  
});



export default MakeInvoice;
