import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Switch } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';

const countryList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo (DRC)', value: 'CD' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Greece', value: 'GR' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Laos', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia', value: 'FM' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'North Korea', value: 'KP' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russia', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Korea', value: 'KR' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syria', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Vatican City', value: 'VA' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
];


const NewReseller = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('BE'); // Default to Belgium
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [vatNumber, setVatNumber] = useState('');

  const [useDifferentShipping, setUseDifferentShipping] = useState(false);
  const [shipFirstName, setShipFirstName] = useState('');
  const [shipLastName, setShipLastName] = useState('');
  const [shipAddress, setShipAddress] = useState('');
  const [shipCity, setShipCity] = useState('');
  const [shipState, setShipState] = useState('');
  const [shipPostcode, setShipPostcode] = useState('');
  const [shipCountry, setShipCountry] = useState('BE'); // Default to Belgium

  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigation = useNavigation();

  const handleAddCustomer = async () => {
    const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/customers';
    const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
    const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

    const newCustomer = {
      username: `${firstName.toLowerCase()}.${lastName.toLowerCase()}`,
      email,
      first_name: firstName,
      last_name: lastName,
      billing: {
        first_name: firstName,
        last_name: lastName,
        company: companyName,
        address_1: address,
        city,
        state,
        postcode,
        country, // Country is selected from the picker
        email,
        phone,
      },
      shipping: {
        first_name: useDifferentShipping ? shipFirstName : firstName,
        last_name: useDifferentShipping ? shipLastName : lastName,
        company: companyName,
        address_1: useDifferentShipping ? shipAddress : address,
        city: useDifferentShipping ? shipCity : city,
        state: useDifferentShipping ? shipState : state,
        postcode: useDifferentShipping ? shipPostcode : postcode,
        country: useDifferentShipping ? shipCountry : country,
        phone: phone,
      },
      meta_data: [
        {
          key: '_billing_vat_number',
          value: vatNumber,
        },
        {
          key: '_vat_number',
          value: vatNumber,
        },
        {
          key: 'vat_number',
          value: vatNumber,
        },
      ],
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
        body: JSON.stringify(newCustomer),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(`Failed to add customer: ${data.message || 'Unknown error'}`);
      }

      setRegistrationSuccess(true);
      clearFields();
    } catch (error) {
      console.error('Error adding customer:', error);
      setErrorMessage(`An error occurred while adding the customer: ${error.message}`);
    }
  };

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setAddress('');
    setCity('');
    setState('');
    setPostcode('');
    setCountry('BE'); // Reset to default country
    setPhone('');
    setCompanyName('');
    setVatNumber('');
    setShipFirstName('');
    setShipLastName('');
    setShipAddress('');
    setShipCity('');
    setShipState('');
    setShipPostcode('');
    setShipCountry('BE'); // Reset to default country
  };

  const handleNewRegistration = () => {
    setRegistrationSuccess(false);
    setErrorMessage('');
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {registrationSuccess ? (
          <View style={styles.successContainer}>
            <Text style={styles.successText}>Customer added successfully!</Text>
            <TouchableOpacity style={styles.button} onPress={handleNewRegistration}>
              <Text style={styles.text}>Register New Customer</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Home')}>
              <Text style={styles.text}>Go to Homepage</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <>
            <TextInput
              style={styles.input}
              placeholder="First Name"
              placeholderTextColor="#888"
              value={firstName}
              onChangeText={setFirstName}
            />
            <TextInput
              style={styles.input}
              placeholder="Last Name"
              placeholderTextColor="#888"
              value={lastName}
              onChangeText={setLastName}
            />
            <TextInput
              style={styles.input}
              placeholder="Email"
              placeholderTextColor="#888"
              value={email}
              onChangeText={setEmail}
              keyboardType="email-address"
            />
            <TextInput
              style={styles.input}
              placeholder="Company Name"
              placeholderTextColor="#888"
              value={companyName}
              onChangeText={setCompanyName}
            />
            <TextInput
              style={styles.input}
              placeholder="VAT Number"
              placeholderTextColor="#888"
              value={vatNumber}
              onChangeText={setVatNumber}
            />
            <TextInput
              style={styles.input}
              placeholder="Address"
              placeholderTextColor="#888"
              value={address}
              onChangeText={setAddress}
            />
            <TextInput
              style={styles.input}
              placeholder="City"
              placeholderTextColor="#888"
              value={city}
              onChangeText={setCity}
            />
            <TextInput
              style={styles.input}
              placeholder="State"
              placeholderTextColor="#888"
              value={state}
              onChangeText={setState}
            />
            <TextInput
              style={styles.input}
              placeholder="Postcode"
              placeholderTextColor="#888"
              value={postcode}
              onChangeText={setPostcode}
            />
            <Picker
              selectedValue={country}
              style={styles.picker}
              onValueChange={(itemValue) => setCountry(itemValue)}
            >
              {countryList.map((country) => (
                <Picker.Item label={country.label} value={country.value} key={country.value} />
              ))}
            </Picker>
            <TextInput
              style={styles.input}
              placeholder="Phone Number"
              placeholderTextColor="#888"
              value={phone}
              onChangeText={setPhone}
              keyboardType="phone-pad"
            />
            <View style={styles.switchContainer}>
              <Text style={styles.switchLabel}>Use different shipping address</Text>
              <Switch
                value={useDifferentShipping}
                onValueChange={setUseDifferentShipping}
              />
            </View>
            {useDifferentShipping && (
              <>
                <Text style={styles.sectionHeader}>Shipping Address</Text>
                <TextInput
                  style={styles.input}
                  placeholder="Shipping First Name"
                  placeholderTextColor="#888"
                  value={shipFirstName}
                  onChangeText={setShipFirstName}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Last Name"
                  placeholderTextColor="#888"
                  value={shipLastName}
                  onChangeText={setShipLastName}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Address"
                  placeholderTextColor="#888"
                  value={shipAddress}
                  onChangeText={setShipAddress}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping City"
                  placeholderTextColor="#888"
                  value={shipCity}
                  onChangeText={setShipCity}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping State"
                  placeholderTextColor="#888"
                  value={shipState}
                  onChangeText={setShipState}
                />
                <TextInput
                  style={styles.input}
                  placeholder="Shipping Postcode"
                  placeholderTextColor="#888"
                  value={shipPostcode}
                  onChangeText={setShipPostcode}
                />
                <Picker
                  selectedValue={shipCountry}
                  style={styles.picker}
                  onValueChange={(itemValue) => setShipCountry(itemValue)}
                >
                  {countryList.map((country) => (
                    <Picker.Item label={country.label} value={country.value} key={country.value} />
                  ))}
                </Picker>
              </>
            )}
            {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
            <TouchableOpacity style={styles.button} onPress={handleAddCustomer}>
              <Text style={styles.text}>Add Customer</Text>
            </TouchableOpacity>
          </>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 10,
    marginVertical: 10,
    width: '80%',
    maxWidth:800
  },
  picker: {
    width: '80%',
    color: 'black',
    backgroundColor: '#fff',
    marginVertical: 10,
    padding:10,
    maxWidth:800
  },
  button: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 20,
    alignItems: 'center',
    marginTop: 20,
    minWidth: 270,
  },
  text: {
    color: 'black',
    fontSize: 23,
  },
  sectionHeader: {
    color: 'black',
    fontSize: 18,
    marginVertical: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  switchLabel: {
    color: 'black',
    fontSize: 16,
    marginRight: 10,
  },
  successContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  successText: {
    color: 'green',
    fontSize: 18,
    marginVertical: 10,
  },
  errorText: {
    color: 'red',
    fontSize: 16,
    marginVertical: 10,
  },
});

export default NewReseller;
