import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';

const NewOrder = ({ navigation }) => (
  <View style={styles.container}>
    <ScrollView contentContainerStyle={styles.scrollView}>
      <TouchableOpacity 
        style={styles.button} 
        onPress={() => navigation.navigate('New Customer', { sourceScreen: 'NewOrder' })}
      >
        <Text style={styles.text}>NEW CUSTOMER</Text>
      </TouchableOpacity>
      <TouchableOpacity 
        style={styles.button} 
        onPress={() => navigation.navigate('Existing Customer', { sourceScreen: 'NewOrder' })}
      >
        <Text style={styles.text}>EXISTING CUSTOMER</Text>
      </TouchableOpacity>
    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 20,
    alignItems: 'center',
    marginBottom: 20,
    minWidth: 270,
        backgroundColor:"#c6c6c62b"
  },
  text: {
    color: 'black',
    fontSize: 18,
  },
});

export default NewOrder;
