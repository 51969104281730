import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Picker, Alert, Platform } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { printAsync } from 'expo-print';
import Base64ImageComponent from '../assets/Base64ImageComponent';

const ConfirmOrder = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { customerData, selectedItems } = route.params || {};

  const [invoiceNo, setInvoiceNo] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Bank Transfer');
  const [vatPercentage, setVatPercentage] = useState('21');

  const formatCurrency = (value) => {
    if (isNaN(value) || value === undefined || value === null) {
      return '0,00';
    }
    return parseFloat(value).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const totalExcludingVAT = selectedItems.reduce((total, item) => total + (parseFloat(item.price) / (1 + parseFloat(vatPercentage) / 100)), 0).toFixed(2);
  const totalIncludingVAT = selectedItems.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
  const vatAmount = (totalIncludingVAT - totalExcludingVAT).toFixed(2);

  const countryNames = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo (Democratic Republic)",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (North)",
    KR: "Korea (South)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MK: "North Macedonia",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States of America",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  };
  

  const printInvoice = async () => {
    if (selectedItems.length === 0) {
      Alert.alert('No Items Selected', 'Please select at least one item to create an invoice.');
      return;
    }

    try {
      const invoiceContent = `
        <html>
          <body>
            <div style="text-align: center;">
              <img src="${Base64ImageComponent()}" alt="Logo" style="width: 250px; height: auto;" />
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
              <div style="border: 1px solid black; padding: 10px; width: 45%;">
                <p style="margin:0"><strong>${customerData.first_name} ${customerData.last_name}</strong></p>
                <p style="margin:0">${customerData.billing.address_1}</p>
                <p style="margin:0">${customerData.billing.postcode}, ${customerData.billing.city}</p>
<p style="margin:0">${countryNames[customerData.billing.country] || customerData.billing.country}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; margin-bottom: 20px; width: 100%;">
              <div style="border: 1px solid black; padding: 10px; width: 45%; display: flex; align-items: center;">
                <p style="margin: 0;"><strong>Invoice No.:</strong> ${invoiceNo || 'CONFIRM-ORDER-12345'}</p>
              </div>
              <div>
                <p style="margin: 0;"><strong>Antwerp</strong>, ${new Date().toLocaleDateString()}</p>
              </div>
            </div>
            <table style="border-collapse: collapse;width: 100%;">
              <thead>
                <tr>
                  <th style="border: 1px solid black;padding:10px">N° Jewel</th>
                  <th style="border: 1px solid black;padding:10px">Product Name</th>
                  <th style="border: 1px solid black;padding:10px">SKU</th>
                  <th style="border: 1px solid black;padding:10px">Price/Unit</th>
                  <th style="border: 1px solid black;padding:10px">Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${selectedItems.map((item, index) => `
                  <tr>
                    <td style="border: 1px solid black;padding:10px">${index + 1}</td>
                    <td style="border: 1px solid black;padding:10px">${item.name}</td>
                    <td style="border: 1px solid black;padding:10px">${item.sku}</td>
                    <td style="border: 1px solid black;padding:10px">${formatCurrency(item.price)} €</td>
                    <td style="border: 1px solid black;padding:10px">${formatCurrency(item.price)} €</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; margin-top: 40px;margin-bottom:20px">
              <div>
                <p style="margin:0"><strong>Payment Method:</strong> ${selectedPaymentMethod}</p>
              </div>
              <div>
                <table style="border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT excl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalExcludingVAT)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>VAT (${vatPercentage}%)</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(vatAmount)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT incl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${formatCurrency(totalIncludingVAT)} €</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="margin-bottom:20px">
              <div style="border: 1px solid black; padding: 10px;width:50%">
                <p style="margin:0;font-size:14px">Please pay in € only to: KBC Bank </p>
                <p style="margin:0;font-size:14px">Name: The shamrock-Orogem jewelers </p>
                <p style="margin:0;font-size:14px">IBAN: BE52737001907509 </p>
                <p style="margin:0;font-size:14px">SWIFT: KREDBEBB</p>
              </div>
            </div>
<div style="position: absolute; bottom: 80px; left: 0; width: 100%; text-align: left; font-size: 10px; max-width: 70%;">
  <p style="margin: 0;">Article 8: Jurisdiction and applicable Law</p>
  <p style="margin: 0;">All disputes arising from this here contract are subject to Belgian Law. The Courts of Antwerp are the forum to resolve any such dispute, notwithstanding the seller's right to designate any other jurisdiction if he so chooses.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">*Krachtens de artikelen 2 en 39bis,1° W.BTW wordt geen Belgische BTW in rekening gebracht.</p>
  <p style="margin: 0;">Above mentioned goods will be considered on consignment until full receipt of the payment of this invoice.</p>
  <p style="margin: 0;">The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions. The seller hereby guarantees that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
  <p style="margin: 0;">Note:</p>
  <p style="margin: 0;">**The diamonds herein invoiced are exclusively of natural origin and untreated based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
</div>
<footer style="position: absolute; bottom: 20px; left: 0; width: 100%; text-align: center; font-size: 12px;">
  <p style="margin: 0;">The Shamrock . Vestingstraat 38 - 2018 Antwerpen - T +32 (0)3 213 27 89 - yannick@juwelenorogem.be</p>
  <p style="margin: 0;">Bank KBC 737-0019075-09 - BTW BE 436 048 553 - H.R. Antwerpen 33.99.44</p>
</footer>
          </body>
        </html>
      `;

      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(invoiceContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: invoiceContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
    }
  };

  const confirmOrder = async () => {
    if (Platform.OS === 'web') {
      const confirmed = window.confirm("Are you sure you want to confirm this order? This will update the stock quantity in WooCommerce.");
      if (!confirmed) return;
  
      try {
        console.log('Confirming order...');
  
        // Check if selected items are present
        if (selectedItems.length === 0) {
          throw new Error('No items selected to update.');
        }
  
        // Update the stock quantity for each item in WooCommerce
        for (const item of selectedItems) {
          console.log(`Processing item with SKU: ${item.sku}`);
          const sku = item.sku;
          const timestamp = new Date().getTime();
          const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
          const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
          const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;
  
          if (!consumerKey || !consumerSecret) {
            throw new Error('WooCommerce API keys are missing.');
          }
  
          const url = `${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&sku=${sku}&_=${timestamp}`;
  
          const response = await fetch(url);
          console.log(`Response status: ${response.status}`);
  
          if (!response.ok) {
            throw new Error(`Failed to fetch product details. HTTP status ${response.status}`);
          }
  
          const product = await response.json();
  
          if (product.length > 0) {
            const productId = product[0].id;
            const newStockQuantity = product[0].stock_quantity - 1;
  
            // Update the product in WooCommerce
            const updateUrl = `${apiUrl}/${productId}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
            console.log(`Updating product with ID: ${productId}, New Stock Quantity: ${newStockQuantity}`);
  
            const updateResponse = await fetch(updateUrl, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ stock_quantity: newStockQuantity }),
            });
  
            if (!updateResponse.ok) {
              throw new Error(`Failed to update stock. HTTP status ${updateResponse.status}`);
            }
  
            console.log(`Successfully updated product ID: ${productId}`);
          } else {
            console.warn(`Product with SKU: ${sku} not found.`);
          }
        }
  
        Alert.alert('Success', 'Order confirmed and stock updated successfully.');
      } catch (error) {
        console.error('Error confirming order:', error);
        Alert.alert('Error', `Failed to confirm order: ${error.message}`);
      }
    } else {
      // This part is used for mobile platforms where Alert works properly.
      Alert.alert(
        "Confirm Order",
        "Are you sure you want to confirm this order?",
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          {
            text: "Confirm",
            onPress: async () => {
              try {
                console.log('Confirming order...');
  
                // Check if selected items are present
                if (selectedItems.length === 0) {
                  throw new Error('No items selected to update.');
                }
  
                // Update the stock quantity for each item in WooCommerce
                for (const item of selectedItems) {
                  console.log(`Processing item with SKU: ${item.sku}`);
                  const sku = item.sku;
                  const timestamp = new Date().getTime();
                  const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
                  const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
                  const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;
  
                  if (!consumerKey || !consumerSecret) {
                    throw new Error('WooCommerce API keys are missing.');
                  }
  
                  const url = `${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&sku=${sku}&_=${timestamp}`;
  
                  const response = await fetch(url);
                  console.log(`Response status: ${response.status}`);
  
                  if (!response.ok) {
                    throw new Error(`Failed to fetch product details. HTTP status ${response.status}`);
                  }
  
                  const product = await response.json();
  
                  if (product.length > 0) {
                    const productId = product[0].id;
                    const newStockQuantity = product[0].stock_quantity - 1;
  
                    // Update the product in WooCommerce
                    const updateUrl = `${apiUrl}/${productId}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;
                    console.log(`Updating product with ID: ${productId}, New Stock Quantity: ${newStockQuantity}`);
  
                    const updateResponse = await fetch(updateUrl, {
                      method: 'PUT',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ stock_quantity: newStockQuantity }),
                    });
  
                    if (!updateResponse.ok) {
                      throw new Error(`Failed to update stock. HTTP status ${updateResponse.status}`);
                    }
  
                    console.log(`Successfully updated product ID: ${productId}`);
                  } else {
                    console.warn(`Product with SKU: ${sku} not found.`);
                  }
                }
  
                Alert.alert('Success', 'Order confirmed and stock updated successfully.');
              } catch (error) {
                console.error('Error confirming order:', error);
                Alert.alert('Error', `Failed to confirm order: ${error.message}`);
              }
            },
          },
        ]
      );
    }
  };
  
  const printCertificate = async (item) => {
    try {
      // Log the full product object to the console
      console.log('Full Product Item:', item);
      
      // Ensure an item is passed
      if (!item || !item.attributes) {
        Alert.alert('No Product Selected', 'Please select a valid product to generate a certificate.');
        return;
      }
  
      // Set the title dynamically
      const title = window.prompt("Enter title for the certificate:", item.name || "Product Certificate");
  
      // Extract product attributes such as metal, carat weight, etc., handling missing data more robustly
      const metal = item.attributes.find(attr => attr.slug === 'pa_metal')?.options[0] || "Unknown Metal";
      const caratWeight = item.attributes.find(attr => attr.slug === 'pa_carat-weight')?.options[0] || "Unknown Carat Weight";
      const stoneQuality = item.attributes.find(attr => attr.slug === 'pa_stone-quality')?.options.join(", ") || "Unknown Quality";
      const giaNumber = item.attributes.find(attr => attr.slug === 'pa_gia-number')?.options[0] || "N/A";
  
      // HTML content for the certificate
      const certificateContent = `
        <html>
          <body style="font-family: Arial, sans-serif;">
            <div style="text-align: center;">
              <h1>${title}</h1>
            </div>
            <div style="margin: 20px;">
              <h2>Product Information</h2>
              <p><strong>Metal:</strong> ${metal}</p>
              <p><strong>Carat Weight:</strong> ${caratWeight}</p>
              <p><strong>Stone Quality:</strong> ${stoneQuality}</p>
              <p><strong>GIA Number:</strong> ${giaNumber}</p>
            </div>
          
          </body>
        </html>
      `;
  
      // Printing the certificate
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(certificateContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: certificateContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing certificate:", error);
      Alert.alert('Error', 'Failed to print the certificate. Please try again.');
    }
  };
  
  

  
  return (
    <View style={styles.container2}>
      <ScrollView style={styles.container} contentContainerStyle={styles.scroll}>
        <Text style={styles.title}>Order Summary</Text>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Invoice No.</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter Invoice Number"
            value={invoiceNo}
            onChangeText={text => setInvoiceNo(text)}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Payment Method</Text>
          <Picker
            selectedValue={selectedPaymentMethod}
            onValueChange={(itemValue) => setSelectedPaymentMethod(itemValue)}
          >
            <Picker.Item label="Cash" value="Cash" />
            <Picker.Item label="Credit Card" value="Credit Card" />
            <Picker.Item label="Bank Transfer" value="Bank Transfer" />
          </Picker>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>VAT Percentage (%)</Text>
          <TextInput
            style={styles.input}
            placeholder="Enter VAT Percentage"
            value={vatPercentage}
            keyboardType="numeric"
            onChangeText={text => setVatPercentage(text)}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Customer Details</Text>
          <Text style={styles.text}>Name: {customerData?.first_name} {customerData?.last_name}</Text>
          <Text style={styles.text}>Email: {customerData?.email}</Text>
          <Text style={styles.text}>Address: {customerData?.billing.address_1}, {customerData?.billing.city}, {customerData?.billing.country}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Selected Products</Text>
          {selectedItems.map((item, index) => (
  <View key={item.id} style={styles.productContainer}>
    <Text style={styles.productText}>{index + 1}. {item.name} - {formatCurrency(item.price)} €</Text>
    <Text style={styles.productText}>SKU: {item.sku}</Text>
    <TouchableOpacity 
                style={styles.button} 
                onPress={() => navigation.navigate('Print Certificate', { selectedItem: item })}
              >
                <Text style={styles.buttonText}>Print Certificate</Text>
              </TouchableOpacity>
  </View>
))}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Total Amounts</Text>
          <Text style={styles.text}>Total VAT excl.: {formatCurrency(totalExcludingVAT)} €</Text>
          <Text style={styles.text}>VAT ({vatPercentage}%): {formatCurrency(vatAmount)} €</Text>
          <Text style={styles.totalAmount}>Total VAT incl.: {formatCurrency(totalIncludingVAT)} €</Text>
        </View>

        <TouchableOpacity style={styles.button} onPress={printInvoice}>
          <Text style={styles.buttonText}>Print Invoice</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={confirmOrder}>
          <Text style={styles.buttonText}>Confirm Order</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={printCertificate}>
  <Text style={styles.buttonText}>Print Certificate</Text>
</TouchableOpacity>

      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20,
    width: 1200,
    alignContent: 'center',
  },
  container2: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20,
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    color: 'white',
    textAlign: 'center',
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 20,
    color: 'white',
    marginBottom: 10,
  },
  text: {
    color: 'white',
    fontSize: 16,
    marginBottom: 5,
  },
  productContainer: {
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    paddingBottom: 5,
    marginBottom: 10,
  },
  productText: {
    color: 'white',
    fontSize: 16,
  },
  totalAmount: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: 'blue',
    padding: 15,
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
  },
  input: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    padding: 10,
    borderRadius: 5,
  },
});

export default ConfirmOrder;
