import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, SafeAreaView, SectionList, StatusBar } from 'react-native';
import moment from 'moment';

const CalendarScreen = () => {
  const [eventsData, setEventsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      setLoading(true);
  
      // Fetch appointments data from WordPress API
      const appointmentsResponse = await fetch('https://juwelenorogem.be/wp-json/wp/v2/appuntamenti?');
      if (!appointmentsResponse.ok) {
        throw new Error('Failed to fetch appointments');
      }
      const appointmentsData = await appointmentsResponse.json();
  
      // Ensure data is an array
      const appointmentsArray = Array.isArray(appointmentsData) ? appointmentsData : [];
  
      // Combine appointments into an events object suitable for display
      const combinedEventsData = combineEventsData(appointmentsArray);
  
      setEventsData(combinedEventsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching appointments:', error);
    }
  };
  
  const combineEventsData = (appointmentsData) => {
    const combinedEvents = [];

    // Get current date and the date two months ahead
    const currentDate = moment().startOf('day');
    const twoMonthsLater = moment().add(2, 'months').endOf('day');

    // Function to parse dates from 'acf.attendance_date'
    const parseAttendanceDate = (dateObject) => {
      if (!dateObject || typeof dateObject !== 'object' || !dateObject.date) {
        console.error('Invalid date object:', dateObject);
        return null;
      }

      // Extract the date value from the object, which could be in 'YYYYMMDD' or 'YYYY-MM-DD' format
      const dateStr = dateObject.date;

      // First try parsing as 'YYYYMMDD', then fall back to 'YYYY-MM-DD'
      let parsedDate = moment(dateStr, 'YYYYMMDD');
      if (!parsedDate.isValid()) {
        parsedDate = moment(dateStr, 'YYYY-MM-DD');
      }

      if (!parsedDate.isValid()) {
        console.error(`Invalid date format: ${dateStr}`);
        return null;
      }

      return parsedDate;
    };

    // Add appointments to combinedEvents
    appointmentsData.forEach((event) => {
      const attendanceDates = event?.acf?.attendance_date;

      if (attendanceDates) {
        if (Array.isArray(attendanceDates)) {
          // If attendanceDates is an array, iterate through each date
          attendanceDates.forEach((dateObject) => {
            const eventDate = parseAttendanceDate(dateObject);
            if (eventDate && eventDate.isBetween(currentDate, twoMonthsLater, 'days', '[]')) {
              combinedEvents.push(createEventObject(event, eventDate));
            }
          });
        } else if (typeof attendanceDates === 'object') {
          // If attendanceDates is a single object
          const eventDate = parseAttendanceDate(attendanceDates);
          if (eventDate && eventDate.isBetween(currentDate, twoMonthsLater, 'days', '[]')) {
            combinedEvents.push(createEventObject(event, eventDate));
          }
        }
      } else {
        console.error(`Failed to parse date for event with ID: ${event.id}`);
      }
    });

    // Group events by date
    const groupedEvents = combinedEvents.reduce((acc, event) => {
      const date = event.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {});

    // Convert grouped events to SectionList data format
    return Object.keys(groupedEvents)
      .map((date) => ({
        title: date,
        data: groupedEvents[date],
      }))
      .sort((a, b) => new Date(a.title) - new Date(b.title)); // Sort events by date
  };

  const createEventObject = (event, eventDate) => {
    return {
      id: event.id,
      name: event.title?.rendered || 'Untitled Appointment',
      content: event.content?.rendered || '',
      type: 'appointment',
      date: eventDate.format('YYYY-MM-DD'),
    };
  };

  const renderItem = ({ item }) => {
    return (
      <View style={styles.item}>
        <Text style={styles.itemName}>{item.name}</Text>
        {item.content ? (
          <Text style={styles.itemContent}>{stripHtmlTags(item.content)}</Text>
        ) : null}
      </View>
    );
  };

  const stripHtmlTags = (html) => {
    return html.replace(/<[^>]+>/g, '');
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar backgroundColor="#000" barStyle="light-content" />
      <View style={styles.container}>
        <Text style={styles.title}>Appointments Calendar</Text>
        {loading ? (
          <View style={styles.loadingContainer}>
            <Text style={{ color: "white", marginBottom: 20, fontSize: 28 }}>Loading Appointments...</Text>
            <ActivityIndicator size="large" color="#fff" />
          </View>
        ) : (
          <SectionList
            sections={eventsData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            renderSectionHeader={({ section: { title } }) => (
              <View style={styles.sectionHeader}>
                <Text style={styles.sectionTitle}>{moment(title).format('MMMM Do YYYY')}</Text>
                <View style={styles.sectionSeparator} />
              </View>
            )}
            ListEmptyComponent={<Text style={styles.emptyText}>No Appointments</Text>}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    width: 800,
    alignSelf: 'center',
  },
  
  safeArea: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 30,
    alignSelf: 'center',
    marginVertical: 20,
    color: 'white',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    flexDirection: 'column',
    backgroundColor: '#eee',
    borderRadius: 5,
    padding: 15,
    marginVertical: 5,
    marginHorizontal: 20,
  },
  itemName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#000',
  },
  itemContent: {
    fontSize: 16,
    color: 'black',
    marginTop: 5,
  },
  emptyText: {
    color: 'white',
    textAlign: 'center',
    marginTop: 20,
    fontSize: 18,
  },
  sectionHeader: {
    backgroundColor: '#fff',
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  sectionTitle: {
    fontSize: 24,
    color: 'black',
  },
  sectionSeparator: {
    height: 1,
    backgroundColor: '#444',
    marginVertical: 10,
  },
});

export default CalendarScreen;
