import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Picker, Alert, Platform } from 'react-native';
import { printAsync } from 'expo-print';

const CertificateScreen = ({ route }) => {
  const { selectedItem } = route.params || {};

  // Debugging: Log the selected item to verify the structure
  console.log('Selected Item:', selectedItem);

  const [title, setTitle] = useState(selectedItem?.name || 'Product Certificate');
  const [goldWeight, setGoldWeight] = useState('');
  const [isGold18k, setIsGold18k] = useState(true); // Toggle for "18k Gold" text
  const [language, setLanguage] = useState('en'); // 'en' for English, 'nl' for Dutch

  // Extract meaningful attributes from the selectedItem
  const getAttribute = (slug) => {
    if (!selectedItem?.attributes) {
      console.error(`Attributes not found in selectedItem: ${JSON.stringify(selectedItem)}`);
      return "N/A";
    }

    const attribute = selectedItem.attributes.find(attr => attr.slug === slug);
    if (!attribute) {
      console.warn(`Attribute with slug '${slug}' not found.`);
      return "N/A";
    }

    // Debugging: Log the found attribute to verify its structure
    console.log(`Found Attribute for ${slug}:`, attribute);

    if (attribute.options && attribute.options.length > 0) {
      return attribute.options[0]; // Ensure we're accessing the first element of `options` correctly
    } else {
      console.warn(`No options found for attribute with slug '${slug}'.`);
      return "N/A";
    }
  };

  // Extract attributes based on their slugs
  const metal = getAttribute('pa_metal');
  const shapeCentralStone = getAttribute('pa_shape-central-stone');
  const weightCentralStone = getAttribute('pa_weight-central-stone');
  const colorCentralStone = getAttribute('pa_color-central-stone');
  const clarityCentralStone = getAttribute('pa_clarity-central-stone');
  const certificate = getAttribute('pa_certificate');
  const certificateNumber = getAttribute('pa_certificate-number');
  const weightSideStones = getAttribute('pa_weight-side-stones');
  const colorSideStones = getAttribute('pa_color-side-stones');
  const claritySideStones = getAttribute('pa_clarity-side-stones');

  const printCertificate = async () => {
    try {
      // HTML content for the certificate
      const certificateContent = `
        <html>
          <body style="font-family: Arial, sans-serif;">
            <div style="text-align: center;">
              <h1>${title}</h1>
            </div>
            <div style="margin: 20px;">
              <p><strong>${isGold18k ? '18k ' : ''}${language === 'en' ? 'Gold Weight:' : 'Goud Gewicht:'}</strong> ${goldWeight}g</p>
              <p><strong>${language === 'en' ? 'Metal:' : 'Metaal:'}</strong> ${metal}</p>
              <p><strong>${language === 'en' ? 'Shape Central Stone:' : 'Vorm Centrale Steen:'}</strong> ${shapeCentralStone}</p>
              <p><strong>${language === 'en' ? 'Weight Central Stone:' : 'Gewicht Centrale Steen:'}</strong> ${weightCentralStone}</p>
              <p><strong>${language === 'en' ? 'Color Central Stone:' : 'Kleur Centrale Steen:'}</strong> ${colorCentralStone}</p>
              <p><strong>${language === 'en' ? 'Clarity Central Stone:' : 'Zuiverheid Centrale Steen:'}</strong> ${clarityCentralStone}</p>
              <p><strong>${language === 'en' ? 'Certificate:' : 'Certificaat:'}</strong> ${certificate} ${certificateNumber}</p>
              <p><strong>${language === 'en' ? 'Weight Side Stones:' : 'Gewicht Zijstenen:'}</strong> ${weightSideStones}</p>
              <p><strong>${language === 'en' ? 'Color Side Stones:' : 'Kleur Zijstenen:'}</strong> ${colorSideStones}</p>
              <p><strong>${language === 'en' ? 'Clarity Side Stones:' : 'Zuiverheid Zijstenen:'}</strong> ${claritySideStones}</p>
              <p><strong>${language === 'en' ? 'Date:' : 'Datum:'}</strong> ${new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
            </div>
          </body>
        </html>
      `;

      // Printing the certificate
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(certificateContent);
        win.print();
        win.close(); 
      } else {
        const { uri } = await printAsync({
          html: certificateContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing certificate:", error);
      Alert.alert('Error', 'Failed to print the certificate. Please try again.');
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={styles.title}>Certificate Information</Text>

        <View style={styles.section}>
          <Text style={styles.label}>Title</Text>
          <TextInput
            style={styles.input}
            value={title}
            onChangeText={(text) => setTitle(text)}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>Gold Weight (g)</Text>
          <TextInput
            style={styles.input}
            value={goldWeight}
            onChangeText={(text) => setGoldWeight(text)}
            keyboardType="numeric"
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>Include "18k"?</Text>
          <Picker
            selectedValue={isGold18k}
            onValueChange={(value) => setIsGold18k(value)}
          >
            <Picker.Item label="Yes" value={true} />
            <Picker.Item label="No" value={false} />
          </Picker>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>Language</Text>
          <Picker
            selectedValue={language}
            onValueChange={(value) => setLanguage(value)}
          >
            <Picker.Item label="English" value="en" />
            <Picker.Item label="Dutch" value="nl" />
          </Picker>
        </View>

        <TouchableOpacity style={styles.button} onPress={printCertificate}>
          <Text style={styles.buttonText}>Print Certificate</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 20,
  },
  scrollContainer: {
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
    width: '100%',
  },
  label: {
    fontSize: 18,
    marginBottom: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    borderRadius: 5,
    fontSize: 16,
  },
  button: {
    backgroundColor: '#007BFF',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
    width: '100%',
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 18,
  },
});

export default CertificateScreen;
