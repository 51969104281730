import React from 'react';
import { ScrollView, StyleSheet, View, Text, TouchableOpacity } from 'react-native';

const Selection = ({ navigation }) => {
  // Helper to simplify button creation
  const createButton = (label, params) => (
    <TouchableOpacity
      style={styles.button}
      onPress={() => navigation.navigate('Inventory', params)}
    >
      <Text style={styles.text}>{label}</Text>
    </TouchableOpacity>
  );

  return (
<ScrollView contentContainerStyle={styles.scrollViewContent}>
<View style={styles.gridContainer}>

<View style={styles.column}>
{createButton('FULL INVENTORY', {})}

        {createButton('All Engagement Rings', { categoryId: 7174 })}
        {createButton('Classic Engagement Rings', { categoryId: 99 })}
        {createButton('Diamond Band Engagement Rings', { categoryId: 100 })}
        {createButton('Halo Engagement Rings', { categoryId: 101 })}
        {createButton('Three-stone Engagement Rings', { categoryId: 103 })}
        {createButton('Fancy Diamond Engagement Rings', { tagId: 5614 })}
      </View>
      <View style={styles.column}>
        {createButton('All Wedding Rings', { categoryId: 3089 })}
        {createButton('Diamond Wedding Rings', { categoryId: 3281 })}
        {createButton('Plain gold Wedding Rings', { categoryId: 3280 })}
        {createButton('Shaped Wedding Rings', { tagId: 3234 })}
      </View>
      <View style={styles.column}>
        {createButton('All Labgrown diamond jewelry', { categoryId: 5962 })}
        {createButton('Labgrown diamond engagement rings', { categoryId: 5986 })}
        {createButton('Labgrown diamond Earrings', { categoryId: 5987 })}
        {createButton('Labgrown diamond Bracelets', { categoryId: 6120 })}
        {createButton('Labgrown diamond Necklaces', { categoryId: 6119 })}
      </View>
      <View style={styles.column}>
        {createButton('Diamond Inventory', { categoryId: 4619 })}
        {createButton('Unique Pieces', { categoryId: 5645 })}
      </View>
      <View style={styles.column}>
        {createButton('All Rings', { categoryId: 63 })}
        {createButton('Diamond Rings', { categoryId: 2076 })}
        {createButton('Gemstone Rings', { categoryId: 2078 })}
        {createButton('Gold Rings', { categoryId: 3288 })}
        {createButton('Pearl Rings', { categoryId: 2080 })}
      </View>
      <View style={styles.column}>
        {createButton('All Earrings', { categoryId: 65 })}
        {createButton('Diamond Earrings', { categoryId: 2083 })}
        {createButton('Gemstone Earrings', { categoryId: 2084 })}
        {createButton('Pearl Earrings', { categoryId: 2085 })}
      </View>
      <View style={styles.column}>
        {createButton('All Bracelets', { categoryId: 66 })}
        {createButton('Diamond Bracelets', { categoryId: 2095 })}
        {createButton('Gemstone Bracelets', { categoryId: 2096 })}
        {createButton('Pearl Bracelets', { categoryId: 2097 })}
      </View>
      <View style={styles.column}>
        {createButton('All Necklaces', { categoryId: 64 })}
        {createButton('Diamond Necklaces', { categoryId: 2089 })}
        {createButton('Gemstone Necklaces', { categoryId: 2090 })}
      </View>
      </View>

    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:"white"
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center', // Centers columns in the ScrollView if less space is needed
    width: '100%', // Ensures it takes full width of the scroll view
  },
  column: {
    minWidth: 200, // Minimum width for each column
    maxWidth: 200, // Maximum width for each column
    padding: 10,
    alignContent:"flex-start"
  },
  button: {
    borderWidth: 1,
    borderColor: 'black',
    padding: 10,
    marginBottom: 10,
    alignItems: 'center',
    backgroundColor:"#c6c6c62b"
  },
  text: {
    color: 'black',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default Selection;
